.navbar {
  width: 100%;
  background: #b4f4fe;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.linksContainer {
  padding: 0 2rem;

}

.navLinks {
  width: 100%;
  list-style: none;
  display: flex;
  justify-content: space-evenly;
  font-size: 1.3rem;
}

.navItem {
  text-decoration: none;
  color: rgb(9, 13, 56);
}

.navbarLogo {
  height: 50px;
}
.textBackground{
  background-color: white;

}