.digitalVaultWrapper {
  /* height: 100vh; */
  padding-top: 60px;
}

.heading {
  color: var(--darkblue);
  font-size: 3rem;
}

.heading2 {
  color: var(--darkblue);
  font-size: 2.5rem;
}

.contentWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}

.content {
  color: var(--black);
  margin-top: 4rem;
  font-size: 1.5rem;
  width: 85%;
}

.content2 {
  color: var(--black);
  margin-top: 2rem;
  font-size: 1.5rem;
  width: 85%;
}

@media screen and (max-width: 768px) {
  .digitalVaultWrapper {
    padding-top: 11rem;
  }

  .heading,
  .heading2 {
    font-size: 2rem;
  }

  .content,
  .content2 {
    font-size: 1rem;
  }
}
.textBackground{
  background-color: white;

}