:root {
  --black: #000000;
  --white: #ffffff;
  --blue: #B5F4FE;
  --orange: #FF971E;
  --darkblue: #090D38;

  --poppins: "Poppins", sans-serif;
  --font-lt: 300;
  --font-md: 500;
  --font-bd: 700;
}

* {
  font-family: var(--poppins);
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.page-break {
  border: none;
  border-radius: 3px;
  height: 3px;
  background-color: var(--orange);
  height: 10px;
  opacity: .9;
  margin: 3rem 0;
}

canvas {
  z-index: -1;
}

/* Keeps the navbar from shifting when the modal is opened */
.sticky-top {
  padding-right: 0 !important;
  margin-right: 0 !important;
}

.modal-open {
  padding-right: 0 !important;
}

/* Removes the blue outline on form inputs */
.form-control:focus {
  box-shadow: none;
  border-color: #090D38;
}
