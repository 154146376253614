.digitalMindWrapper {
  padding-top: 60px;
}

.heading {
  color: var(--darkblue);
  font-size: 3rem;
}

.contentWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}

.content {
  color: var(--black);
  margin-top: 4rem;
  font-size: 1.5rem;
  width: 85%;
}

@media screen and (max-width: 768px) {
  .digitalMindWrapper {
    padding-top: 11rem;
  }

  .heading {
    font-size: 2rem;
  }

  .content {
    font-size: 1rem;
  }
}
.textBackground{
  background-color: white;

}