.heroWrapper {
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.textAnimationContainer {
  z-index: 2;
  width: auto;
  text-align: center;
  color: #090D38;
  position: relative;
  padding-top: 8vh;
  animation: dropIn 2s ease-out forwards;
  background-color: white;
}

.textAnimationContainerH1 {
  font-size: 7rem;
  margin: 0;
  padding: 0;
  animation: fadeInUp 2s ease-out 1s forwards;
  background-color: white;
}

.textAnimationContainerH2 {
  font-size: 5rem;
  margin: 0;
  padding: 0;
  animation: fadeInUp 2s ease-out 1.5s forwards;
  background-color: white;
}

.textAnimationContainerH3 {
  font-size: 6rem;
  margin: 0;
  padding: 0;
  animation: fadeInUpColorChange 2s ease-out 2s forwards;
  background-color: white;
}

.copyContainer {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.textAnimationContainerP {
  width: 75%;
  text-align: center;
  font-size: 1.5rem;
  opacity: 0;
  margin: 0;
  padding: 0;
  animation: fadeIn 3s ease-out 2.5s forwards;
  background-color: white;
}

.btnContainer {
  display: flex;
  justify-content: flex-end;
}

@keyframes fadeInUpColorChange {
  0% {
    opacity: 0;
    transform: translate3d(0, 20%, 0);
  }

  50% {
    color: #090D38;
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    color: rgb(255, 151, 30);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes dropIn {
  0% {
    opacity: 0;
    transform: translateY(-550%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


@media screen and (max-width: 768px) {
  .heroWrapper {
    height: 90vh;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .textAnimationContainer {
    padding-top: 0;
    animation: none;
    text-align: left;
    background-color: white;
  }

  .textAnimationContainerH1 {
    font-size: 4rem;
    animation: none;
    background-color: white;
  }

  .textAnimationContainerH2 {
    font-size: 3rem;
    animation: none;
    background-color: white;
  }

  .textAnimationContainerH3 {
    font-size: 3.5rem;
    animation: none;
    color: var(--orange);
    background-color: white;
  }

  .textAnimationContainerP {
    font-size: 1rem;
    animation: none;
    opacity: 1;
    text-align: left;
    background-color: white;
  }

  .copyContainer {
    display: block;
    /* justify-content: center; */
  }

  .btnContainer {
    display: flex;
    justify-content: center;
  }

}
.textBackground{
  background-color: white;

}