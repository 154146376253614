.footerContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 3rem;
}

.copyright {
  margin-left: 1rem;
  font-weight: var(--font-lt);
}

@media screen and (max-width: 768px) {
  .footerContainer {
    justify-content: flex-start;
  }
}
.textBackground{
  background-color: white;

}