.modal {
  opacity: .97;
  color: var(--darkblue);
  font-weight: var(--font-lt);
}

.modalTitle {
  margin-left: 2rem;
  text-align: center;
  font-weight: var(--font-md);
}

.getStartedBtn {
  opacity: 0;
  animation: fadeIn 2s ease-out 3.5s forwards;
  background-color: #FF971E;
  border: none;
  margin-left: auto;
  height: 150px;
  width: 200px;
  text-align: left;
  padding: 1rem 1rem 1rem 1.5rem;
  font-size: 1.2rem;
}

.submitBtn {
  background-color: #FF971E;
  border: none;
  margin-bottom: 1rem;
}

.getStartedBtn:hover,
.getStartedBtn:focus-visible,
.getStartedBtn:active,
.submitBtn:hover,
.submitBtn:focus-visible,
.submitBtn:active {
  background-color: #B5F4FE !important;
  color: #090D38 !important;
  box-shadow: none;
}

.requiredFieldsP {
  font-size: 0.75rem;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {
  .getStartedBtn {
    opacity: 1;
    animation: none;
    margin-left: unset;
    height: unset;
    width: unset;
    text-align: center;
    padding: 1rem 1.5rem;
    font-size: 1rem;
    margin-top: 1rem;
  }
}
.textBackground{
  background-color: white;

}
